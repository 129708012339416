import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import ArrowLeftActiveDarkTheme from '@icons/arrows/arrow-left-active-dark-theme.svg';
import ArrowLeftActive from '@icons/arrows/arrow-left-active.svg';
import ArrowLeftDarkTheme from '@icons/arrows/arrow-left-dark-theme.svg';
import ArrowLeft from '@icons/arrows/arrow-left.svg';
import ArrowRightActiveDarkTheme from '@icons/arrows/arrow-right-active-dark-theme.svg';
import ArrowRightActive from '@icons/arrows/arrow-right-active.svg';
import ArrowRightDarkTheme from '@icons/arrows/arrow-right-dark-theme.svg';
import ArrowRight from '@icons/arrows/arrow-right.svg';
import { useCurrentTheme, THEME } from '@src/api/local/theme';

import { setSearchParams } from '../../utils/searchParams';

import styles from './pagination.module.sass';

type PaginationProps = {
  maxPage: number;
  pageQueryParam?: string;
};

const Pagination: React.FC<PaginationProps> = ({ maxPage, pageQueryParam = 'page' }) => {
  const router = useRouter();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pages = [...Array(maxPage + 1).keys()].slice(1);

  const colorTheme = useCurrentTheme();
  const isLightTheme = colorTheme.data?.theme === THEME.light;

  useEffect(() => {
    setCurrentPage(Number(router.query[pageQueryParam]) || 1);
  }, [pageQueryParam, router.query]);

  if (!maxPage || maxPage <= 1) return <></>;

  return (
    <div className={styles.pagination}>
      <div className={styles.leftArrowContainer}>
        <div className={styles.leftArrowContent}>
          {currentPage === 1 ? (
            <div>{isLightTheme ? <ArrowLeft /> : <ArrowLeftDarkTheme />}</div>
          ) : (
            <div
              onClick={() => {
                if (currentPage > 1) {
                  setSearchParams({ [pageQueryParam]: currentPage - 1 });
                }
              }}
            >
              {isLightTheme ? <ArrowLeftActive /> : <ArrowLeftActiveDarkTheme />}
            </div>
          )}
          <p>Previous</p>
        </div>
      </div>

      <>
        {currentPage !== 1 && (
          <div
            className={styles.paginationItem}
            onClick={() => setSearchParams({ [pageQueryParam]: 1 })}
            role="button"
          >
            <span>1</span>
          </div>
        )}

        {currentPage > 3 && (
          <div className={styles.paginationItem}>
            <span>...</span>
          </div>
        )}
        {currentPage >= 3 && (
          <div
            className={styles.paginationItem}
            onClick={() => setSearchParams({ [pageQueryParam]: currentPage - 1 })}
            role="button"
          >
            <span>{currentPage - 1}</span>
          </div>
        )}

        <div className={styles.paginationItemSelected}>
          <div className={styles.paginationItemContent}>
            <span>{currentPage}</span>
          </div>
        </div>
        {maxPage - currentPage > 1 && (
          <div
            className={styles.paginationItem}
            onClick={() => setSearchParams({ [pageQueryParam]: currentPage + 1 })}
            role="button"
          >
            <span>{currentPage + 1}</span>
          </div>
        )}

        {maxPage - currentPage > 2 && (
          <div className={styles.paginationItem}>
            <span>...</span>
          </div>
        )}

        {currentPage !== maxPage && (
          <div
            className={styles.paginationItem}
            onClick={() => setSearchParams({ [pageQueryParam]: pages[pages.length - 1] })}
            role="button"
          >
            <span>{pages[pages.length - 1]}</span>
          </div>
        )}
      </>

      <div className={styles.rightArrowContainer}>
        <div className={styles.rightArrowContent}>
          <p>Next</p>
          {currentPage === maxPage ? (
            <div>{isLightTheme ? <ArrowRight /> : <ArrowRightDarkTheme />}</div>
          ) : (
            <div
              onClick={() => {
                if (currentPage < maxPage) {
                  setSearchParams({ [pageQueryParam]: currentPage + 1 });
                }
              }}
            >
              {isLightTheme ? <ArrowRightActive /> : <ArrowRightActiveDarkTheme />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pagination;
